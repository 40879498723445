import React from 'react'
function FirstElementPaginate() {
  return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="surface1">
        <path d="M 3.105469 10 L 11.550781 1.550781 C 11.90625 1.195312 11.90625 0.621094 11.550781 0.265625 C 11.195312 -0.0898438 10.621094 -0.0898438 10.265625 0.265625 L 1.175781 9.359375 C 1.003906 9.527344 0.910156 9.757812 0.910156 10 C 0.910156 10.242188 1.003906 10.472656 1.175781 10.644531 L 10.265625 19.734375 C 10.445312 19.910156 10.675781 20 10.910156 20 C 11.140625 20 11.375 19.910156 11.550781 19.734375 C 11.90625 19.378906 11.90625 18.804688 11.550781 18.449219 Z M 3.105469 10 " fill="white"/>
        <path d="M 10.375 10 L 18.824219 1.550781 C 19.179688 1.195312 19.179688 0.621094 18.824219 0.265625 C 18.46875 -0.0898438 17.894531 -0.0898438 17.539062 0.265625 L 8.449219 9.359375 C 8.277344 9.527344 8.183594 9.757812 8.183594 10 C 8.183594 10.242188 8.277344 10.472656 8.449219 10.644531 L 17.539062 19.734375 C 17.714844 19.910156 17.949219 20 18.183594 20 C 18.414062 20 18.648438 19.910156 18.824219 19.734375 C 19.179688 19.378906 19.179688 18.804688 18.824219 18.449219 Z M 10.375 10 " fill="white"/>
        </g>
        </svg>
    )
}
export default FirstElementPaginate