import React, { useContext, useMemo, useState, useEffect } from 'react'
import Pagination from "react-js-pagination"
import LazyLoad from 'react-lazyload';

import RightPaginate from "../../images/rightPaginate"
import LeftPaginate from "../../images/leftPaginate"
import FirstElementPaginate from "../../images/firstElementPaginate"
import LastElementPaginate from "../../images/lastElementPaginate"

import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'


// import ListItem from 'src/components/categoryPages/parts/listItem'
// import ListItemSearchRes from 'src/components/categoryPages/parts/listItemSearchRes'

import loadable from '@loadable/component'

const ListItem = loadable(() => import('../categoryPages/parts/listItem'))
const ListItemSearchRes = loadable(() => import('../categoryPages/parts/listItemSearchRes'))


const Default = ({ page, pageContext, products, searchIncArchive, location }) => {
  const state = useContext(GlobalStateContext)

  const ListItemUsed = page.AcfGatsbyId.pageid === 'search-results' ? ListItemSearchRes : ListItem

  const filterType = page.AcfGatsbyId.pageid === 'archive' ? 'archive' : 'common'

  const activeFilters = state.activeFilters
  const activeTypeFilters = state.activeTypeFilters
  const activePurposeFilters = state.activePurposeFilters
  const activeCategoryFilters = state.activeCategoryFilters


  const filterProductArchive = (item) => {

    const pass = {
      co: [],
      ty: [],
      pu: [],
      ca: []
    }

    item.cPTTXattributes && item.cPTTXattributes.nodes.forEach(attr => {

      const name = attr.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
      const section = attr.productattracf.section


      if(section === null) {

        if( activeFilters.includes(name) ) {
          pass.co.push(name)
        }

      }
      else if(section === 'type') {

        if( activeTypeFilters.includes(name) ) {
          pass.ty.push(name)
        }

      }
      else if(section === 'purpose') {

        if( activePurposeFilters.includes(name) ) {
          pass.pu.push(name)
        }

      }
      else if(section === 'category') {

        if( activeCategoryFilters.includes(name) ) {
          pass.ca.push(name)
        }

      }


    })

    const filtred = 

    (activeFilters.length > 0 && pass.co.length === 0)
    || (activeTypeFilters.length > 0 && pass.ty.length === 0)
    || (activePurposeFilters.length > 0 && pass.pu.length === 0)
    || (activeCategoryFilters.length > 0 && pass.ca.length === 0)

    item.filtred = filtred

    return [item]

  } 
  const filterProduct = (item) => {


    const pass = {
      co: [],
    }

    item.cPTTXattributes && item.cPTTXattributes.nodes.forEach(attr => {

      const name = attr.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")

      if( activeFilters.includes(name) ) {
        pass.co.push(name)
      }

    })

    item.filtred = (activeFilters.length > 0 && pass.co.length === 0)

    return [item]

  }


  const initProducts = () => {
    // return products.nodes
    const temp = products.nodes.sort((a, b) => {
      return ( Date.parse(a.date) < Date.parse(b.date) ) ? 1 : -1
    })
    return [...temp]
  }
  const [productsArr, setProductsArr] = useState(() => initProducts())

  // useEffect(() => {
  useMemo(() => {
    // setProductsArr(products.nodes)
    const temp = products.nodes.sort((a, b) => {
      return ( Date.parse(a.date) < Date.parse(b.date) ) ? 1 : -1
    })
    setProductsArr([...temp])
  }, [products.nodes, searchIncArchive])

  // PRODUCT LIST FOR PRODUCT PAGE PAGINATION
  const [products4productPage, setProducts4productPage] = useState([])
  useEffect(() => {
    const temp = []
    productsArr.forEach((el) => {
      temp.push({
        id: el.id,
        slug: el.slug,
        title: el.title,
      })
    })
    setProducts4productPage(temp)
  }, [productsArr])


  // const[cssDelay, setcssDelay] = useState(false)
  // useLayoutEffect(() => {
  //   setcssDelay(true)
  //   const timer = setTimeout(() => {
  //     setcssDelay(false)
  //   }, 244);
  //   return () => clearTimeout(timer);
  // }, [])

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const customCrumbLabel = page.name || page.title
  if(page.baseName) {
    crumbs[1].crumbLabel = page.baseName
  }
  if(page.thirdName) {
    crumbs[2].crumbLabel = page.thirdName
  }


  //////////////////// PAGINATION ////////////////////
  const initActivePage = () => {
    return ( location && location.search && typeof location.search === 'string' ) ? 
      ( location.search.includes('?page=') ?
      Math.floor( location.search.substring( location.search.indexOf('=') + 1 ) )
        : 1
      ) 
    : 1
  }
  const [activePage, setActivePage] = useState( () => initActivePage() )
  const productsPerPage = 24
  const pageRange = 5

  // const handlePageChange = (pageNumber) => {
  //   setActivePage(pageNumber)
  // }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)

    //  HISTORY/BROWSER GO/BACK MANAGE
    // if(location && location.host) {
    //   // FOR DEVELOPMENT, WITHOUT PATH PREFIX
    //   if(location.host === 'localhost:8000' || location.host === 'localhost:9000') {
    //     if(pageNumber >= 1) {
    //       window.history.pushState( null, `${pageContext.siteSlug === 'shared' ? page.title : page.name} - Page ${pageNumber}`, `${pageContext.pagePath === '/' ? '/' : `/${pageContext.pagePath}`}?page=${pageNumber}` )
    //     }
    //   }
    //   // FOR PRODUCTION, INCLUDING PATH PREFIX
    //   else {
    //     if(pageNumber >= 1) {
    //       window.history.pushState( null, `${pageContext.siteSlug === 'shared' ? page.title : page.name} - Page ${pageNumber}`, `${pageContext.pagePath === '/' ? pathPrefix : `${pathPrefix}/${pageContext.pagePath}`}?page=${pageNumber}` )
    //     }
    //   }
    // }


  }

  // useEffect(() => {

  //   // console.log('____USEEFFECT____activePage:  ', activePage)
  //   // console.log('____USEEFFECT____filtredItemsList:  ', filteredItems)

  //   setProducts2show(
  //     (filteredItems && filteredItems.length > 0) ? filteredItems.slice(activePage * productsPerPage - productsPerPage, activePage * productsPerPage) : null
  //   )

  // },[filteredItems,activePage,activeFilters,activeTypeFilters,activePurposeFilters,activeCategoryFilters])


  // console.log('active page ___-_-_-_-', activePage)
  // console.log('location ___-_-_-_-', location)
  // console.log('page path ___-_-_-_-', pageContext.pagePath)


  useEffect(() => {
    if( location && location.search && typeof location.search === 'string' ) {
      if( location.search.includes('?page=') ) {
        setActivePage( Math.floor( location.search.substring( location.search.indexOf('=') + 1 ) ) )
      }
    }
  },[location])


  // useEffect(() => {
  //   return globalHistory.listen(({ action }) => {
  //     // console.log(action)
  //     console.log(action, '_________', pageContext.pagePath)
  //     console.log(action, '_________', activePage)

  //     // if(action === 'POP') {}

  //   })
  // }, [])  // eslint-disable-line react-hooks/exhaustive-deps



  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // useEffect(() => {
  //   if(location && location.host && typeof activePage === 'number') {
  //     // FOR DEVELOPMENT, WITHOUT PATH PREFIX
  //     if(location.host === 'localhost:8000') {
  //       if(activePage > 1) {
  //         window.history.pushState( {activePage:activePage}, `${pageContext.siteSlug === 'shared' ? page.title : page.name} - Page ${activePage}`, `${pageContext.pagePath === '/' ? '/' : `/${pageContext.pagePath}`}?page=${activePage}` )
  //       }
  //       else if (activePage === 1) {
  //         window.history.pushState( {activePage:activePage}, `${pageContext.siteSlug === 'shared' ? page.title : page.name} - Page ${activePage}`, `${pageContext.pagePath === '/' ? '/' : `/${pageContext.pagePath}`}` )
  //       }
  //     }
  //     // FOR PRODUCTION, INCLUDING PATH PREFIX
  //     else {
  //       if(activePage > 1) {
  //         window.history.pushState( {activePage:activePage}, `${pageContext.siteSlug === 'shared' ? page.title : page.name} - Page ${activePage}`, `${pageContext.pagePath === '/' ? pathPrefix : `${pathPrefix}/${pageContext.pagePath}`}?page=${activePage}` )
  //       }
  //       else if (activePage === 1) {
  //         window.history.pushState( {activePage:activePage}, `${pageContext.siteSlug === 'shared' ? page.title : page.name} - Page ${activePage}`, `${pageContext.pagePath === '/' ? pathPrefix : `${pathPrefix}/${pageContext.pagePath}`}` )
  //       }
  //     }
  //   }
  // },[activePage])
  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


  //////////// FILTER BEFORE PAGINATION /////////////  
  let filteredItems = []
  {(productsArr && productsArr.length > 0) && productsArr.map((preitem, i) => {
    const [item] = filterType === 'archive' ? filterProductArchive(preitem) : filterProduct(preitem)
    if(item.filtred === false){

      filteredItems.push(item)

      // filteredItems = [...filteredItems, item]

    }    
  })}
  ///////////////////////////////////////////////////


  // useEffect(() => {

  //   // console.log('____USEEFFECT____activePage:  ', activePage)
  //   // console.log('____USEEFFECT____filtredItemsList:  ', filteredItems)

  //   setProducts2show(
  //     (filteredItems && filteredItems.length > 0) ? filteredItems.slice(activePage * productsPerPage - productsPerPage, activePage * productsPerPage) : null
  //   )

  // },[activePage,activeFilters,activeTypeFilters,activePurposeFilters,activeCategoryFilters])


  // const initProducts2show = () => {
  //   return (filteredItems && filteredItems.length > 0) ? filteredItems.slice(activePage * productsPerPage - productsPerPage, activePage * productsPerPage) : null
  // }
  // const [products2show, setProducts2show] = useState( () => initProducts2show() )

  //// UPDATE PAGINATIONS ACTIVE PAGE AFTER FILTERING PRODUCT LIST ////
  useEffect(() => {
    if( (filteredItems.length / productsPerPage) < activePage ) {
      setActivePage( Math.ceil( filteredItems.length / productsPerPage ) )
    }
  },[filteredItems.length])
  ////////////////////////////////////////////////////////////////////

  //// SCROLL TOP AFTER PAGE CHANGE ////
  useEffect(() => {
    typeof window !== 'undefined' && window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
      behavior: 'auto'
    })
  },[activePage])

  
  return <>
    {/* {(page.content || featuredImage.fluid || pageTitle || page.description ) && 
      <div className={`pageContentContainer`} >

        {exitFromArchive ?
          <div className={`archiveTitleAndExitWrapper`}>
            {!mobile && <h1>{pageTitle}</h1>}
            <a href={withPrefix('/')} className={`exitFromArchive`}><span className="signX">X</span> - {exitFromArchive}</a>
          </div>
        :
          !mobile && <h1>{pageTitle}</h1>
        }
        

        {(page.AcfGatsbyId.pageid !== 'home' && !mobile) && <div className={`productLayout help-bar`} 
            style={
              cssDelay ? 
              {
                opacity: '0'
              } : 
              {
                opacity: '1'
              } 
            }
          >

            <div
              className={`crumbWrap ${!cssDelay ? '' : 'transition'}`}
            >        
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=""
                crumbLabel={customCrumbLabel}
              />
            </div>

          </div>
        }
        {featuredImage.fluid && 
        <div 
          style={{ 
            marginBottom: '20px', 
            marginTop: '20px', 
            maxWidth: '1320px', 
          }} 
          className="featuredCategoryImage"
        >
          <GatsbyImage
            image={featuredImage.gatsbyImageData}
            alt={featuredImage.alt}
            title={featuredImage.title}
            description={featuredImage.description} />
        </div>}

        {page.content && 
          <div 
            className={`wpContent`} 
            style={{maxWidth: '1280px'}} 
            dangerouslySetInnerHTML={{ __html: page.content }}
          ></div>}

        {page.description && 
          <div 
            className={`wpContent`} 
            style={{maxWidth: '1280px'}} 
            dangerouslySetInnerHTML={{ __html: page.description }}
          ></div>}

      </div>
    } */}

    {/* {productsArr && productsArr.length < 1 && <div className={`emptyProductList`}>{emptyProductList}</div>} */}

    <div 
      className={`productsList`}
    >
      
      {(filteredItems && filteredItems.length > 0) && filteredItems.slice(activePage * productsPerPage - productsPerPage, activePage * productsPerPage).map((filteredItem, i) => {
      {/* {products2show && products2show.map((filteredItem, i) => { */}
        return(
          <LazyLoad height={200} once key={i} >
            <ListItemUsed 
              // key={i}
              item={filteredItem} 
              searchIncArchive={searchIncArchive} 
              pagePath={pageContext.pagePath} 
              crumbs={crumbs}
              customCrumbLabel={customCrumbLabel} 
              products4productPage={products4productPage} 
              i={ (activePage - 1) * productsPerPage + i } 
            />
          </LazyLoad>
        )
      })}

    </div>
    <LazyLoad height={200} once >
      <Pagination
        activePage={activePage}
        itemsCountPerPage={productsPerPage}
        totalItemsCount={filteredItems.length}
        pageRangeDisplayed={pageRange}
        onChange={handlePageChange}
        itemClass={`paginateItem`}
        linkClass={`paginateLink`}
        disabledClass={`disabled`}
        innerClass={`pagination`}
        activeClass={`active`}
        activeLinkClass={`activeLink`}
        itemClassPrev={`prev`}
        itemClassNext={`next`}
        itemClassFirst={`first`}
        itemClassLast={`last`}
        prevPageText={<LeftPaginate/>}
        nextPageText={<RightPaginate/>}
        firstPageText={<FirstElementPaginate/>}
        lastPageText={<LastElementPaginate/>}
      />
    </LazyLoad>

    {(page.productcatacf && page.productcatacf.categoryDescriptionBottom) && 
      <div className={`pageContentContainer`} >
        {page.productcatacf.categoryDescriptionBottom && 
          <div 
            className={`wpContent`} 
            // style={{maxWidth: '1280px'}} 
            dangerouslySetInnerHTML={{ __html: page.productcatacf.categoryDescriptionBottom }}
            >
            </div>}
      </div>
    }

  </>;

}
export default Default