import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PageTransition from 'gatsby-plugin-page-transitions'
import { Link } from 'gatsby'
// import Header from 'src/components/common/header.js'/
import Footer from 'src/components/common/footer'
import logo from '../../../static/logoWhite.svg'
import CookieConsent from "react-cookie-consent"


const LayoutCore = ({ children, pageContext, location }) => {
  const graphqlResult = useStaticQuery(graphql`
    query LayoutQuery {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      langLabel: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "header-bar"}}) {
        acfheaderbar {
          languageSwitchLabel
        }
      }
      defaultPhone: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-phone"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
          line2GenralInfosInfo
        }
      }
      defaultMail: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-mail"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
          line2GenralInfosInfo
        }
      }
      siteSlogan: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "site-slogan"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      mobNavLabel: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "header-bar"}}) {
        acfheaderbar {
          navLabelShowAll
          mobileNav1Label
          mobileNav2Label
        }
      }
      burgerMenu: allWpMenu(filter: {name: {eq: "burgermenu"}}) {
        edges {
          node {
            slug
            name
            menuItems {
              nodes {
                label
                path
                id
                parentId
                cssClasses
                order
                childItems {
                  nodes {
                    label
                    path
                    id
                    parentId
                    cssClasses
                    order
                    childItems {
                      nodes {
                        label
                        path
                        id
                        parentId
                        cssClasses
                        order
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      privacyPolicy: wpPage(AcfGatsbyId: {pageid: {eq: "privacy-policy"}}) {
        slug
      }
      cookies: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "cookies"}}) {
        cookieslabels {
          acceptCookies
          declineCookies
          textCookies
          linkLabelCookies
        }
      }
    }
  `)

  const privacyPolicy = graphqlResult.privacyPolicy.slug
  const cookielabels = graphqlResult.cookies.cookieslabels
  
  const phoneHref = graphqlResult.defaultPhone.acfgeneralinfos_layout.lineGenralInfosInfo
  const phoneHref2 = graphqlResult.defaultPhone.acfgeneralinfos_layout.line2GenralInfosInfo
  const phoneHrefClean = phoneHref.replace(/\s+/g, '')
  const phoneHrefClean2 = phoneHref2 ? phoneHref2.replace(/\s+/g, '') : ''
  
  // HEADERPROPS
  // const siteSlogan = graphqlResult.siteSlogan.acfgeneralinfos_layout.lineGenralInfosInfo
  // const mailHref = graphqlResult.defaultMail.acfgeneralinfos_layout.lineGenralInfosInfo
  // const mailHref2 = graphqlResult.defaultMail.acfgeneralinfos_layout.line2GenralInfosInfo
  // const mobNavLabel = graphqlResult.mobNavLabel.acfheaderbar.navLabelShowAll
  // const mobileNav1Label = graphqlResult.mobNavLabel.acfheaderbar.mobileNav1Label
  // const mobileNav2Label = graphqlResult.mobNavLabel.acfheaderbar.mobileNav2Label
  // const burgerMenu = graphqlResult.burgerMenu.edges[0].node.menuItems.nodes
  // const typeMenu = pageContext.nav0
  // const secondMenuB = pageContext.nav2b
  // const lang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo


  const initialWindoWidth = () => {
    return typeof document !== `undefined` ? document.body.offsetWidth : false
  }
  const[windowWidth, setWindowWidth] = useState(() => initialWindoWidth())
  const handleResize = () => {
    setWindowWidth(typeof document !== `undefined` ? document.body.offsetWidth : false)
  }
  // useLayoutEffect(() => {
  useEffect(() => {
    typeof window !== `undefined` && window.addEventListener('resize', handleResize, true)
    handleResize()
    return () => {
      typeof window !== `undefined` && window.removeEventListener('resize', handleResize, true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps 

  const[mobile, setMobile] = useState() 
  useLayoutEffect(() => {
    setMobile(windowWidth > 764 ? false : true)
  }, [windowWidth])


  // ONLY true WHEN PAGE LOAD/CONTENT CHABGE - USE ONLY TO STOP SOME ANIMATIONS ON PAGE LOAD
  const[pageLoad, setpageLoad] = useState(false)
  useLayoutEffect(() => {
    setpageLoad(true)
    const timer = setTimeout(() => {
      setpageLoad(false)
    }, 933);
    return () => clearTimeout(timer);
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps 


  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
        location: location,
        mobile: mobile,
        pageLoad: pageLoad
    }),
  );


  return (
    <>

      {/* <div className={`topMenu`}>

        <Header 
          pageContext={pageContext ? pageContext : false} 
          windowWidth={windowWidth} 
          headerMenu={burgerMenu} 

          typeMenu={typeMenu}
          secondMenuB={secondMenuB}           
          mobNavLabel={mobNavLabel}
          
          phoneHrefClean={phoneHrefClean}
          phoneHrefClean2={phoneHrefClean2}
          phoneHref={phoneHref}
          
          mailHref={mailHref}
          mailHref2={mailHref2}
          
          location={location}
          mobile={mobile}
          searchIncArchive={searchIncArchive}
          isArchive={isArchive}

          lang={lang}
          langLabel={graphqlResult.langLabel.acfheaderbar.languageSwitchLabel}

          mobileNav1Label={mobileNav1Label}
          mobileNav2Label={mobileNav2Label}

          siteSlogan={siteSlogan}
        />

      </div> */}

      <PageTransition
        className={'pageTrans'}
        defaultStyle={
          {
            opacity: '1',
            transition: 'opacity 266ms ease-in',
          }
        }
        transitionStyles={{
          entering: { opacity: '1' },
          entered: { opacity: '1' },
          exiting: { opacity: '0' },
          exited: { opacit: '0' }
        }}
        transitionTime={266}
      >

      <div 
        className={`productLayout`} 
        style={{position: 'relative'}}
      >
        {childrenWithProps}
        {/* {children} */}
      </div>

      </PageTransition>


      <CookieConsent
        disableStyles={true}
        enableDeclineButton
        location="none"
        buttonText={cookielabels.acceptCookies}
        declineButtonText={cookielabels.declineCookies}
        cookieName="gatsby-gdpr-google-analytics"
        buttonWrapperClasses="cookieButtonContainer"
        declineButtonClasses="cookiesDecline"

        // debug={true}

      >
        {cookielabels.textCookies}
        <Link
          to={`/${privacyPolicy}`}
          duration={1.6}
          direction='right'
          entryOffset={400}
        >
          {cookielabels.linkLabelCookies}
        </Link>
      </CookieConsent>


      <Footer 
        pageContext={pageContext} 
        logo={logo} 
        windowWidth={windowWidth} 
        phoneHrefClean={phoneHrefClean}
        phoneHrefClean2={phoneHrefClean2}
        phoneHref={phoneHref}
        phoneHref2={phoneHref2}

        pageLoad={pageLoad}
      />

    </>
  )
}
export default LayoutCore