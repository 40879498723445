import React from 'react'
function FirstElementPaginate() {
  return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="surface1">
        <path d="M 18.824219 9.355469 L 9.734375 0.265625 C 9.378906 -0.0898438 8.804688 -0.0898438 8.449219 0.265625 C 8.09375 0.621094 8.09375 1.195312 8.449219 1.550781 L 16.894531 10 L 8.449219 18.449219 C 8.09375 18.804688 8.09375 19.378906 8.449219 19.734375 C 8.625 19.910156 8.859375 20 9.089844 20 C 9.324219 20 9.554688 19.910156 9.734375 19.734375 L 18.824219 10.644531 C 18.996094 10.472656 19.089844 10.242188 19.089844 10 C 19.089844 9.757812 18.996094 9.527344 18.824219 9.355469 Z M 18.824219 9.355469 " fill="white"/>
        <path d="M 11.816406 10 C 11.816406 9.757812 11.722656 9.527344 11.550781 9.355469 L 2.460938 0.265625 C 2.105469 -0.0898438 1.53125 -0.0898438 1.175781 0.265625 C 0.820312 0.621094 0.820312 1.195312 1.175781 1.550781 L 9.625 10 L 1.175781 18.449219 C 0.820312 18.804688 0.820312 19.378906 1.175781 19.734375 C 1.351562 19.910156 1.585938 20 1.816406 20 C 2.050781 20 2.285156 19.910156 2.460938 19.734375 L 11.550781 10.640625 C 11.722656 10.472656 11.816406 10.242188 11.816406 10 Z M 11.816406 10 " fill="white"/>
        </g>
        </svg>
    )
}
export default FirstElementPaginate