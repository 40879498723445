import React from 'react'
function phoneSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.335" height="18.34" viewBox="0 0 18.335 18.34">
      <g id="telephone-handle-silhouette" transform="translate(0 0.001)">
        <path id="Path_7164" data-name="Path 7164" d="M18.31,14.21q-.078-.234-.99-.69-.247-.141-.7-.39t-.827-.456q-.371-.209-.7-.4-.052-.039-.325-.228a3.661,3.661,0,0,0-.463-.283.853.853,0,0,0-.371-.091,1.081,1.081,0,0,0-.651.371,6.342,6.342,0,0,0-.717.808,7.852,7.852,0,0,1-.69.808.99.99,0,0,1-.6.371.886.886,0,0,1-.294-.065c-.117-.043-.206-.081-.267-.11s-.165-.091-.312-.183-.231-.141-.248-.15A13.688,13.688,0,0,1,7.1,11.253,13.681,13.681,0,0,1,4.832,8.193q-.013-.026-.15-.248T4.5,7.632a2.823,2.823,0,0,1-.11-.267.881.881,0,0,1-.071-.292.99.99,0,0,1,.371-.6,7.845,7.845,0,0,1,.808-.69,6.34,6.34,0,0,0,.811-.717,1.081,1.081,0,0,0,.371-.652.849.849,0,0,0-.091-.371,3.62,3.62,0,0,0-.283-.463q-.189-.274-.228-.325-.2-.325-.4-.7T5.22,1.732q-.248-.456-.39-.7-.456-.912-.69-.99A.729.729,0,0,0,3.865,0a4.291,4.291,0,0,0-.92.13A5.158,5.158,0,0,0,2.054.4,3.492,3.492,0,0,0,.674,1.992,5.05,5.05,0,0,0,.01,4.415,5.03,5.03,0,0,0,.055,5.1a5.62,5.62,0,0,0,.16.748q.117.417.189.619t.267.723q.2.521.234.637a11.8,11.8,0,0,0,1.081,2.279,19.312,19.312,0,0,0,2.807,3.446,19.322,19.322,0,0,0,3.445,2.807,11.813,11.813,0,0,0,2.279,1.081q.117.039.637.235t.723.267q.2.071.618.189a5.67,5.67,0,0,0,.749.163,4.831,4.831,0,0,0,3.107-.619,3.49,3.49,0,0,0,1.589-1.381,5.129,5.129,0,0,0,.274-.892,4.282,4.282,0,0,0,.13-.92.726.726,0,0,0-.033-.272Z" transform="translate(-0.01 0)" fill="#f3cf45"/>
      </g>
    </svg>
  )
}
export default phoneSvg