import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Link } from 'gatsby'


const SubmenuList = ({ pagePath, sublist, parentPath, grandParentPath, pathMod, depth, defaultState, mobile, mobNavLabel, parentLabel, navId }) => {


  // const initButt = () => {
  //   // return !sublist.nodes.some(x => `/${x.path}/` === `/${pagePath}`) ? false : true
  //   return sublist.nodes.some(x => `/${x.path}/` === `/${pagePath}`) || sublist.nodes.some(y => y.childItems?.nodes.some(z => `/${z.path}/` === `/${pagePath}`))
  // }


  const initButt = () => {
    return !mobile
  }

  const [itemOpen, setItemOpen] = useState(() => initButt())
  // const [itemOpen, setItemOpen] = useState(mobile ? false : true)

  useEffect(() => {
    setItemOpen(!mobile)
  }, [mobile])




  const[cssDelay, setcssDelay] = useState(false)
  useLayoutEffect(() => {    
    setcssDelay(true)
    const timer = setTimeout(() => {
      setcssDelay(false)
    }, itemOpen ? 411 : 1)
    return () => clearTimeout(timer);
  }, [itemOpen])


  return (
    <>
      <button
        className={`subMenuButton menuButton ${itemOpen ? 'subMenuButtonOpen' : 'subMenuButtonClosed'}`}
        onClick={() => {setItemOpen(!itemOpen)}}
        onKeyDown={() => {setItemOpen(!itemOpen)}}
        tabIndex="0"

        // style={(defaultState === 'row' && mobile && depth === 2) ? {
        //   width: '100%'
        // } : {}}

      >
        {itemOpen ? 
          <span style={{
            fontSize:'14px',
          }}>▲</span> 
          : 
          <span style={{
            fontSize:'14px',
            }} >▼</span>}
      </button>
      <ul 
        className={`subLevel subLevel-${depth} ${itemOpen ? 'open' : ''}`}
        style={
          itemOpen ?
          { 
            height: `${cssDelay ? `${sublist.nodes.length * 30 + 42}px` : 'auto'}`,
            // maxHeight: `1200px`,
            overflow: 'hidden'
          }
          :
          {
            height: `${cssDelay ? `${sublist.nodes.length * 30 + 42}px` : '1px'}`,
            padding: `0`
            // maxHeight: `0px`
          }
        }
      >
        {/* {(defaultState === 'row' && mobile) &&
        <li
          className={`subLevelLink subLevelLink-${depth} menuLink ${parentPath === pagePath ? 'nav-bar_link--active' : ''}`}
          style={{
            order: 1000
          }}
        >
          <Link
            to={`/${parentPath}`}
            activeClassName="nav-bar_link--active"
            partiallyActive={false}
            itemProp="url"
          >
            <span>{mobNavLabel + ' ' + parentLabel}</span>
          </Link>
        </li>
        } */}
        {sublist.nodes.length > 0 && sublist.nodes.map((item, key) => {
          return (
            <li 
              key={key} 
              className={`subLevelLink subLevelLink-${depth} menuLink ${item.path === pagePath ? 'nav-bar_link--active' : ''} ${item.childItems?.nodes.length > 0 ? 'itemHaveChildren' : ''}`}
              style={typeof item.order === 'number' ? {
                order: `${item.order}`,
              } :{}}
            >
              {item.path.startsWith('https://') ?
                <a
                  href={item.path}
                  itemProp="url"
                  // target="_blank"
                >
                  <span>{item.label}</span>
                </a>
                :
                <Link
                  to={pathMod === 'absolute' ? `/${item.path}/` : item.path}
                  activeClassName="nav-bar_link--active"
                  partiallyActive={true}
                  itemProp="url"

                  state={{sideNavUseLoc: navId}}
                >
                  <span>{item.label}</span>
                </Link>
              }
              {item.childItems?.nodes.length ?
                <SubmenuList
                  pagePath={pagePath}
                  sublist={item.childItems}
                  parentPath={item.path}
                  parentLabel={item.label}
                  grandParentPath={parentPath}
                  pathMod={pathMod}
                  depth={depth + 1}
                  mobNavLabel={''}
                  navId={navId}
                  
                />
              : ''}
            </li>
          )
        })}
      </ul>
    </>
  )
}



const MenuList = ({ pagePath, list, pathMod, defaultState, mobile, mobNavLabel, navId }) => {
  const menu = list
  return (
    <ul className={`topLevel ${defaultState === 'row' ? 'rowOrient' : ''}`}>

      {menu && menu.map((item, key) => {
        return (
          <li 
            key={key} 
            className={`topLevelLink menuLink ${item.path === `/${pagePath}` ? 'nav-bar_link--active' : ''} ${item.childItems.nodes.length > 0 ? 'itemHaveChildren' : ''}`}
            style={typeof item.order === 'number' ? {
              order: `${item.order}`,
            } :{}}
          >
            {/* {(defaultState === 'row' && mobile) ? 
            <a href="#">              
              <span>{item.label}</span> 
            </a>
            :
            <Link
              to={pathMod === 'absolute' ? `/${item.path}/` : item.path}
              activeClassName="nav-bar_link--active"
              partiallyActive={false}
              // partiallyActive={true}
              itemProp="url"
            >
              <span>{item.label}</span>
            </Link>
            } */}
            {item.path.startsWith('https://') ?
              <a
                href={item.path}
                itemProp="url"
                // target="_blank"
              >
                <span>{item.label}</span>
              </a>
              :
              <Link
                to={pathMod === 'absolute' ? `/${item.path}/` : item.path}
                activeClassName="nav-bar_link--active"
                partiallyActive={false}
                // partiallyActive={true}
                itemProp="url"

                state={{sideNavUseLoc: navId}}
              >
                <span>{item.label}</span>
              </Link>
            }
            {item.childItems.nodes.length > 0 ?
              <SubmenuList
                pagePath={pagePath}
                sublist={item.childItems}
                parentPath={item.path}
                parentLabel={item.label}
                grandParentPath={''}
                pathMod={pathMod}
                depth={2}
                defaultState={defaultState}
                mobile={mobile}
                mobNavLabel={mobNavLabel}
                navId={navId}
              />              
            : ''}
          </li>
        )
      })}
    </ul>
  )
}
export default MenuList