import React from 'react'
function YoutubeSvg() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
      <g id="surface1">
      <path d="M 23.066406 5.003906 C 22.199219 3.976562 20.597656 3.554688 17.542969 3.554688 L 6.457031 3.554688 C 3.332031 3.554688 1.703125 4.003906 0.839844 5.097656 C 0 6.167969 0 7.742188 0 9.921875 L 0 14.078125 C 0 18.300781 1 20.445312 6.457031 20.445312 L 17.542969 20.445312 C 20.191406 20.445312 21.660156 20.074219 22.609375 19.164062 C 23.585938 18.234375 24 16.710938 24 14.078125 L 24 9.921875 C 24 7.625 23.933594 6.039062 23.066406 5.003906 Z M 15.40625 12.574219 L 10.371094 15.207031 C 10.261719 15.265625 10.136719 15.292969 10.015625 15.292969 C 9.875 15.292969 9.734375 15.253906 9.613281 15.179688 C 9.382812 15.039062 9.238281 14.789062 9.238281 14.519531 L 9.238281 9.273438 C 9.238281 9.003906 9.382812 8.75 9.613281 8.609375 C 9.84375 8.472656 10.132812 8.460938 10.371094 8.585938 L 15.40625 11.199219 C 15.664062 11.332031 15.824219 11.597656 15.824219 11.886719 C 15.824219 12.175781 15.664062 12.441406 15.40625 12.574219 Z M 15.40625 12.574219 "/>
      </g>
      </svg>
    </>
  )
}
export default YoutubeSvg