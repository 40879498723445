import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'gatsby'
// import { withPrefix } from "gatsby"

import loadable from '@loadable/component'

import MenuList from 'src/components/common/menuList'
import LangSwitchMobile from 'src/components/common/langSwitch/langSwitchMobile'
import HeaderTxtSlider from 'src/components/common/headerTxtSlider'

// import logoBlack from '../../../static/logoBlack.svg'
import logo from '../../../static/logoWhite.svg'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

import Phone from 'src/images/socialIcons/phone'
import 'src/styles/nojs.scss'
import 'src/styles/archive.scss'
import 'src/styles/global.scss'
import 'src/styles/header.scss'
import 'src/styles/footer.scss'
import 'src/styles/category.scss'
import 'src/styles/productPage.scss'
import 'src/styles/faq.scss'
import 'src/styles/howToOrder.scss'
import 'src/styles/contact.scss'

import FbIco from 'src/images/socialIcons/facebook.js'
import InIco from 'src/images/socialIcons/instagram.js'
import LiIco from 'src/images/socialIcons/linkedin.js'
import PiIco from 'src/images/socialIcons/pinterest.js'
import TikTokIco from 'src/images/socialIcons/tiktok.js'
import StravaIco from 'src/images/socialIcons/strava.js'
import YtIco from 'src/images/socialIcons/youtube.js'

// import SearchPage from 'src/components/layouts/searchPage'
const SearchPage = loadable(() => import('../layouts/searchPage'))


const Header = ({ pageContext, headerMenu, windowWidth, typeMenu, secondMenuB, mobNavLabel, phoneHrefClean, phoneHrefClean2, phoneHref, mailHref, mailHref2, siteSlogan, location, mobile, lang, langLabel, searchIncArchive, isArchive, mobileNav1Label, mobileNav2Label, search, searchQString }) => {

  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    dispatch({ type: 'CURRENT_LANGUAGE', value: lang })
  }, [])
  
  const toggleMenu = (e) => {
    dispatch({ type: 'TOGGLE_MENU' })
  }

  useEffect(() => {
    state.menu && dispatch({ type: 'TOGGLE_MENU' })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps 

  const[cssDelay, setcssDelay] = useState(false)
  useLayoutEffect(() => {
    // !state.menu &&
    setcssDelay(true)
    const timer = setTimeout(() => {
      setcssDelay(false)
    }, 333);
    return () => clearTimeout(timer);
  }, [state.menu])

  // useEffect(() => {
  //   document.querySelector('html').classList.add("websiteHasJS")
  // }, [])

  

  const [searchPopupState, setSearchPopupState] = useState(false)
  const toggleSearch = () => {
    if(searchPopupState) {
      setSearchPopupState(false)
      dispatch({ type: 'SEARCH_AUTOCOMPLETE', value: false })   
    }
    else {
      setSearchPopupState(true)
    }
  }
  useEffect(() => {
    setSearchPopupState(false)
    dispatch({ type: 'SEARCH_AUTOCOMPLETE', value: false })
  }, [location])


  // console.log(pageContext)
  // console.log(state)


  return (
    <>

    {
    // windowWidth <= 750 && 
    true && 
    <div className="lang-switch mobile" style={{fontWeight: '300',lineHeight:'32px'}}>
      <div className='langTitle'>
        <span>{langLabel}:</span>
      </div>
      <div className="lang-switchIN">
        <LangSwitchMobile site={``} />
      </div>
    </div>
    }

    <header className={`header ${state.menu ? 'header--open' : ''}`}>

    <HeaderTxtSlider windowWidth={windowWidth} />

      <div className="lang-bar">

        <div className={`socIcoWrap`}>
          <a href="https://www.facebook.com/themodernforms" target="_blank" rel="noreferrer" className={`socIco`}>
            <FbIco />
          </a>
          <a href="https://www.instagram.com/themodernforms/" target="_blank" rel="noreferrer" className={`socIco`}>
            <InIco />
          </a>
          <a href="https://www.linkedin.com/company/modern-forms" target="_blank" rel="noreferrer" className={`socIco`}>
            <LiIco />
          </a>
          <a href="https://pl.pinterest.com/themodernforms/_created/" target="_blank" rel="noreferrer" className={`socIco`}>
            <PiIco />
          </a>
          <a href="https://www.tiktok.com/@themodernforms" target="_blank" rel="noreferrer" className={`socIco`}>
            <TikTokIco />
          </a>
          <a href="https://www.strava.com/clubs/964300" target="_blank" rel="noreferrer" className={`socIco`}>
            <StravaIco />
          </a>
          <a href="https://www.youtube.com/channel/UCPmAyp3GvSFdkBWlIeHnZOQ/" target="_blank" rel="noreferrer" className={`socIco`}>
            <YtIco />
          </a>
        </div>

        <div className="right-wrapper">

          <a href={`tel:${phoneHrefClean}`}>
            {<Phone/>}
            {phoneHref}
          </a>

          <a href={`mailto:${mailHref}`} className={`cta2`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.337" height="14.523" viewBox="0 0 21.337 14.523">
              <g id="Group_699" data-name="Group 699" transform="translate(-214.238 -117.38)">
                <g id="Group_695" data-name="Group 695" transform="translate(215.408 117.38)">
                  <path id="Path_7333" data-name="Path 7333" d="M224.6,126.417l8.755-8.755c.078-.078.148-.159.262-.281H214.658q4.543,4.573,9.039,9.092C224.046,126.823,224.2,126.807,224.6,126.417Z" transform="translate(-214.658 -117.38)" fill="#f3cf45"/>
                </g>
                <g id="Group_696" data-name="Group 696" transform="translate(216.661 126.232)">
                  <path id="Path_7334" data-name="Path 7334" d="M225.968,120.724c-.173-.173-.248-.114-.385.025-.61.624-1.237,1.232-1.845,1.859a.5.5,0,0,1-.839,0c-.619-.638-1.262-1.251-1.873-1.9-.2-.215-.3-.2-.5,0-1.739,1.756-3.489,3.5-5.233,5.247-.072.072-.22.117-.173.265H231.4a.85.85,0,0,0-.07-.131Q228.645,123.413,225.968,120.724Z" transform="translate(-215.108 -120.557)" fill="#f3cf45"/>
                </g>
                <g id="Group_697" data-name="Group 697" transform="translate(228.313 118.063)">
                  <path id="Path_7335" data-name="Path 7335" d="M226.389,117.72q-3.486,3.482-6.975,6.961c-.256.254-.061.343.078.482q2.951,2.959,5.9,5.918c.373.373.7.329.961-.145a1.507,1.507,0,0,0,.192-.733c0-4.171,0-8.343,0-12.578A1,1,0,0,0,226.389,117.72Z" transform="translate(-219.289 -117.625)" fill="#f3cf45"/>
                </g>
                <g id="Group_698" data-name="Group 698" transform="translate(214.238 118.102)">
                  <path id="Path_7336" data-name="Path 7336" d="M221.151,125.068c.22-.22.234-.329.006-.554-2.288-2.271-4.561-4.553-6.885-6.874a1.682,1.682,0,0,0-.033.178q0,6.324,0,12.651a.923.923,0,0,0,.209.621c.189.223.346.346.638.05Q218.1,128.08,221.151,125.068Z" transform="translate(-214.238 -117.639)" fill="#f3cf45"/>
                </g>
              </g>
            </svg>
            {mailHref}
          </a>

          
          {/* {windowWidth > 750 && 
            <button className="lang-switch desktop" style={{fontWeight: '300',lineHeight:'30px'}}>
              <LangSwitch site={``} />
            </button>
          } */}
        </div>
        
      </div>


      <div className="nav-bar">


        <div className="logo">
          <Link
            // to={`/${pageContext.realSlugs[pageContext.siteSlug]}`}
            // to={withPrefix('/')}
            // to={`/?page=1`}
            to={`/`}
            duration={2}
            direction="right"
            // state={{ prevPath: pageContext.siteSlug }}
          >
            <img src={logo} alt="logo" style={{width:'60px',display:'block'}}></img>
            <span className="siteSlogan">{siteSlogan}</span>
          </Link>
          
        </div>

        <div className="rightWrapper">

          <div className="search-wrapper">

            <button 
              onClick={() => toggleSearch()}
              onKeyDown={() => toggleSearch()}
              className={`search-icon ${searchPopupState ? 'search--open-icon' : 'search--close-icon'}`}
            >
              {searchPopupState ? 
                <svg id="Warstwa_1" data-name="Warstwa 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g>
                    <line x1="17.8" y1="1.8" x2="1.8" y2="17.8"/>
                    <path d="M352.8,328.34l-16,16a1.8,1.8,0,0,0,2.55,2.54l16-16a1.8,1.8,0,0,0-2.55-2.54Z" transform="translate(-336.27 -327.81)"/>
                  </g>
                  <g>
                    <line x1="17.8" y1="17.8" x2="1.8" y2="1.8"/>
                    <path d="M355.35,344.34l-16-16a1.8,1.8,0,0,0-2.55,2.54l16,16a1.8,1.8,0,0,0,2.55-2.54Z" transform="translate(-336.27 -327.81)"/>
                  </g>
                </svg>
              :
              <svg 
              version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              >
                <g>
                  <path id="search-big" d="M21.8,19.7l-5.2-5.2c-0.1-0.1-0.3-0.2-0.4-0.3c0.9-1.3,1.4-2.8,1.4-4.5c0-4.3-3.5-7.9-7.9-7.9S2,5.4,2,9.8
                    s3.5,7.9,7.9,7.9c1.7,0,3.3-0.5,4.5-1.4c0,0.2,0.1,0.3,0.3,0.4l5.2,5.2c0.4,0.4,1.3,0.3,1.8-0.3C22.1,20.9,22.2,20.1,21.8,19.7z
                    M9.8,14.8c-2.8,0-5-2.3-5-5s2.3-5,5-5s5,2.3,5,5S12.6,14.8,9.8,14.8z"/>
                </g>
              </svg>
              }

            </button>

            {/* {searchPopupState &&  */}
            {(searchPopupState || searchQString) && 
              <SearchPage 
                location={location} 
                windowWidth={windowWidth} 
                searchIncArchive={searchIncArchive} 
                isArchive={isArchive} 
                mobile={mobile}

                searchPopupState={searchPopupState}     //  TO JEST CHYBA ZBEDNE ?? robi kmlase css, ktora zawsze jest

                searchQString={searchQString}
              />
            }

            </div>

            <span
              className="burger"

              // style={state.menu ? {position:'fixed'} :  {}}
              
              // ___SCROLL FOLLOWING BURGER
              // style={state.menu ? {position:'fixed'} : windowWidth <= 750 ? {
              //   top: `${scroll}px`
              // } : {}}

              onClick={toggleMenu}
              onKeyDown={toggleMenu}
              tabIndex="0"
              role="button"
            >
              {!state.menu ?

                <div 
                  style={{
                    width:'50px',
                    height:'60px',
                    // position:'absolute',
                    paddingLeft:'12px',
                    paddingRight:'12px',
                    boxSizing:'border-box',
                    zIndex:'1001',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    // top: `${cssDelay ? '-28px' : '44px'}`,
                    opacity: `${cssDelay ? '0' : '1'}`
                  }}
                >
                  <div className={`burgerA`}></div>
                  <div className={`burgerB`}></div>
                  <div className={`burgerA`}></div>
                  <div className={`burgerB`}></div>
                  <div className={`burgerA`}></div>
                  <div className={`burgerB`}></div>
                </div>
                :
                <div 
                  style={{
                    width:'50px',
                    height:'60px',
                    position:'fixed',
                    paddingLeft:'12px',
                    paddingRight:'12px',
                    boxSizing:'border-box',
                    zIndex:'1001',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    // top: `${cssDelay ? '44px' : '-28px'}`,
                    opacity: `${cssDelay ? '0' : '1'}`
                  }}
                >
                  <div className={`burgerA burgerStr`}></div>
                  <div className={`burgerB burgerStr`}></div>
                  <div className={`burgerA burgerContr`}></div>
                  <div className={`burgerB burgerContr`}></div>
                </div>
              }

            </span>

        </div>

        <nav style={state.menu ? { flexBasis: '100%' } : {}}>
          <div className={`headNavWrap`}>

            {mobile && <h3>{mobileNav1Label}</h3>}

            <MenuList 
              pagePath={pageContext.pagePath} 
              list={typeMenu} 
              pathMod={'absolute'} 
              defaultState={''}
              mobile={mobile}
              mobNavLabel={''}

              navId={'n1'}
            />
            <MenuList 
              pagePath={pageContext.pagePath} 
              list={secondMenuB} 
              pathMod={'absolute'} 
              defaultState={'row'}
              mobile={mobile}
              mobNavLabel={mobNavLabel}

              navId={'n2'}
            />

            {mobile && <h3>{mobileNav2Label}</h3>}

            <MenuList 
              pagePath={pageContext.pagePath} 
              list={headerMenu} 
              pathMod={'wp'} 
              defaultState={''}
              mobile={mobile}
              mobNavLabel={''}
            />

          </div>
        </nav>

      </div>

      {state.menu && 
        <div 
          style={{
            minHeight:'100vh',
            minWidth:'100vw',
            position:'fixed',
            top:'0',
            left:'0',
            cursor: 'pointer'
          }} 
          className={`navClose`} 
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
          tabIndex="0"
          role="button"
        >
        </div>
      }
    </header>
  </>
  )
}
export default Header