import React from 'react'
function RightPaginate() {
  return (
        <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1003_4602)">
        <path d="M1.0474 19.5454C0.342155 19.2518 0.396405 18.5759 0.853005 18.1145C3.44794 15.4811 6.01575 12.8198 8.59713 10.1724C8.64686 10.1211 8.71015 10.0792 8.80056 9.94867C8.73727 9.91138 8.66494 9.88808 8.61521 9.83681C6.00671 7.16613 3.40273 4.48613 0.798755 1.81545C0.563674 1.57309 0.432571 1.30742 0.536549 0.962514C0.690256 0.473123 1.2644 0.29601 1.65771 0.612949C1.70744 0.654897 1.75716 0.701506 1.80237 0.752775C4.60075 3.62853 7.40364 6.50429 10.1975 9.38936C10.3286 9.52453 10.4281 9.71096 10.4778 9.89274C10.5411 10.1071 10.4597 10.3215 10.3105 10.494C10.2608 10.5499 10.2111 10.6012 10.1613 10.6525C7.40816 13.4816 4.65499 16.3154 1.90183 19.1399C1.74812 19.2984 1.55373 19.4102 1.37742 19.5454C1.2644 19.5454 1.1559 19.5454 1.0474 19.5454Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_1003_4602">
        <rect width="10" height="19.0909" fill="white" transform="translate(0.5 0.45459)"/>
        </clipPath>
        </defs>
        </svg>
    )
}
export default RightPaginate