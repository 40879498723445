import React, { useState, useEffect, useMemo } from 'react'
import { useStaticQuery, graphql } from "gatsby"


const HeaderTxtSlider = () => {
  const graphqlResult = useStaticQuery(graphql`
    query txtSlides {
      wpSlides: allWpCptSlide {
        edges {
          node {
            title
            featuredImage {
              node {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const wpSlides = graphqlResult.wpSlides.edges
  const queue = useMemo(() => [...wpSlides, ...wpSlides, ...wpSlides, ...wpSlides], [wpSlides])
  const [index, setIndex] = useState(1)

  useEffect(() => {
    let time = setInterval(() => {
      if(index < queue.length - 1) {
        setIndex(index + 1)
      }
      else if (index === queue.length - 1) {
        setIndex(0)
      }
    }, 4000);
    return () => clearInterval(time)
  }, [index])


  return (
    <div 
      className={`header-slider`}
    >

      {queue.length > 0 &&
        queue.map((slide, i) => {

          return(

            <div
              key={i}
              className={`single-slide`}

              style={
                i === index ?
                {
                  left: '0',
                  transition: 'left 888ms ease-in-out, opacity 50ms ease-in-out',
                  opacity: '1'
                } :
                i < index ?
                {
                  left: '100%',
                  transition: 'left 888ms ease-in-out, opacity 50ms ease-in-out 777ms',
                  opacity: '0'
                } :
                i > index ?
                {
                  left: '-100%',
                  transition: 'left 888ms ease-in-out, opacity 50ms ease-in-out 777ms',
                  opacity: '0'
                } :
                {}
              }

            >
              
              <img src={slide.node.featuredImage.node.localFile.publicURL} alt="" width={'25px'} height={'25px'}></img>

              <span>
                {slide.node.title}
              </span>

            </div>

          )
        })
      }

    </div>
  )
}
export default HeaderTxtSlider