import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"


const LangSwitchUnit = ( site ) => {
  const graphqlResult = useStaticQuery(graphql`
    query siteLangMob {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
    }
  `)
  const currentLang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo

  const slugMap = {
    'pl-PL': {
      brand: 'nasza-marka',
      sport: 'sport',
      business: 'okolicznosciowe',
    },
    'en-EN': {
      brand: 'our-brand',
      sport: 'sport',
      business: 'occasional',
    },
    'de-DE': {
      brand: 'unsere-marke',
      sport: 'sport',
      business: 'Sonderveranstaltungen',
    }
  }

  const sitesEcosys = [
    { lang: 'pl-PL', link: 'https://modernforms.pl/nasze-realizacje/', label: 'polski' },
    { lang: 'en-EN', link: 'https://themodernforms.com/our-projects/', label: 'english' },
    { lang: 'de-DE', link: 'https://modernforms.de/unsere-projekte/', label: 'deutsch' }
  ]

  const siteParam = site.site ? site.site : ''

  const sitesFiltred = sitesEcosys.map((item) => {
    return item.lang !== currentLang ? { lang: item.lang, link: `${item.link}${siteParam !== '' ? `${slugMap[item.lang][siteParam]}/` : ''}`, label: item.label } : { lang: '', link: item.label, label: item.label } 
  })

  let currentInd
  let currentObj
  for(let i = 0; sitesFiltred.length > i; i++) {
    if(sitesFiltred[i].lang === '') {
      currentInd = i
      currentObj = sitesFiltred[i]
    } 
  }
  sitesFiltred.splice(currentInd, 1)
  sitesFiltred.splice(0, 0, currentObj)

  const[dropdown, setDropdown] = useState(false)


  return (
    <>
      {sitesFiltred && sitesFiltred.map((site, i, arr) => {
        // const lastIndex = arr.length - 1;
        if (site.link === site.label) {
          return(
            <span 
              key={i} 
              className={`langMob`} 
              onClick={() => setDropdown(dropdown ? false : true)}
              onKeyDown={() => setDropdown(dropdown ? false : true)}
              tabIndex="0"
              role="button"
            >
              {/* <a href="" style={{position:'relative',zIndex:'10000000'}}>
                <span style={{fontWeight: '300',fontSize:'14px'}}>{site.label}</span>
                {!dropdown ? 
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" fill="white" class="svg-inline--fa fa-chevron-down fa-w-14 notHover" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{marginLeft: '8px', width: '12px', height: '12px', transform: 'translateY(1px)'}}><path fill="white" class="notHover" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                 : 
                
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" fill="white" class="svg-inline--fa fa-chevron-up fa-w-14 notHover" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{marginLeft: '8px', width: '12px', height: '12px', transform: 'translateY(1px)'}}><path fill="white" class="notHover" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                }
              </a> */}
              <div style={{position:'relative',zIndex:'10000000', width:'100%'}}>
                <span style={{fontWeight: '300',fontSize:'14px'}}>{site.label}</span>
                {!dropdown ? 
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" fill="white" class="svg-inline--fa fa-chevron-down fa-w-14 notHover" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{marginLeft: '8px', width: '12px', height: '12px', transform: 'translateY(1px)'}}><path fill="white" class="notHover" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                 : 
                
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" fill="white" class="svg-inline--fa fa-chevron-up fa-w-14 notHover" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{marginLeft: '8px', width: '12px', height: '12px', transform: 'translateY(1px)'}}><path fill="white" class="notHover" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                }
              </div>
              {/* {i !== lastIndex && <span style={{fontWeight: '700'}}> / </span>} */}
            </span>
          )
        } else {
          return(
            <span key={i} className={`langMob hidden ${dropdown ? 'activate' : ''}`}>
              <a href={site.link} style={{position:'relative',zIndex:'10000000'}} rel="noreferrer">
                <span style={{fontWeight: '300',fontSize:'14px',opacity:'0.8'}}>{site.label}</span>
              </a>
              {/* {i !== lastIndex && <span style={{fontWeight: '700'}}> / </span>} */}
            </span>
          )
        }
      })}

      {dropdown && <div 
        style={
          {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            cursor: 'pointer',
            zIndex: '1000',

          }
        }
        onClick={() => setDropdown(dropdown ? false : true)}
        onKeyDown={() => setDropdown(dropdown ? false : true)}
        tabIndex="0"
        role="button"
        aria-label="change language"
      ></div>}

    </>
  )
}
export default LangSwitchUnit