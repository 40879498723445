import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'


// export const Seo = ({ description, keywords, title, image, url, author }) => {
const SEO = ({ seo, slug, gatsbyId, subpageid, pageType, canonicalCombi, productArchive }) => {
  const state = useContext(GlobalStateContext)
  const data = useStaticQuery(graphql`
    query DefaultSeoQuery {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
        pathPrefix
      }
      wp {
        generalSettings {
          description
          title
        }
        seo {
          schema {
            siteName
            wordpressSiteName
            siteUrl
            inLanguage
            companyName
            companyOrPerson
            companyLogo {
              mediaItemUrl
            }
            logo {
              mediaItemUrl
            }
            personLogo {
              mediaItemUrl
            }
          }
          breadcrumbs {
            showBlogPage
            separator
            searchPrefix
            prefix
            homeText
            enabled
            boldLast
            archivePrefix
            notFoundText
          }
          social {
            facebook {
              url
              defaultImage {
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              cardType
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
          openGraph {
            frontPage {
              title
              description
              image {
                altText
                sourceUrl
                mediaItemUrl
              }
            }
            defaultImage {
              altText
              sourceUrl
              mediaItemUrl
            }
          }
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
              schema {
                raw
              }
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
              schema {
                raw
              }
            }
          }
        }
      }
    }
  `)

  // console.log(seo)
  // console.log(seo.schema?.raw)
  // console.log(seo.canonical)
  // console.log(canonicalCombi)

  // console.log(combineSeo)

  // console.log(Object.keys(combineSeo).length)

  // console.log([...Object.keys(combineSeo).map((el) => {
  //   return (typeof combineSeo[el].title !== 'undefined' && combineSeo[el].title !== '') ? combineSeo[el].title : ''
  // })].filter((el2) => {
  //   return (el2 !== '' && el2 !== null)
  // }))


  const metaTitle = seo.title || data.wp.generalSettings.title
        
  const metaDescription = seo.metaDesc || data.wp.generalSettings.description

  const metaKeywords = [seo.focuskw] || ["", ""]

  const lang = data.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo

  const siteLang = lang === 'pl-PL' ? 'pl' : lang === 'en-EN' ? 'en' : lang === 'de-DE' ? 'de' : ''

  // __BODY CLASS PARTS:
  // gatsbyId
  const gatsby_Id = gatsbyId ? `body-${gatsbyId}` : ''
  // burger menu state
  const menu_state = state.menu ? `menu-open` : ''
  // search autocomplete state
  const autocomp = state.searchInputAutoComp ? `autocomp-open` : ''
  // site language
  const body_lang = `body-lang-${siteLang}`
  // subpage (optional)
  const subpage_id = subpageid ? `body-${subpageid}` : ''
  // page type (category, product, other)
  const page_type = `body-page-${pageType === 'product' ? 'single body-product' : pageType === 'single' ? 'single body-page-wp' : pageType}`
  // active filters (dynamic)
  // const active_filters = state.activeFilters.length > 0 ? state.activeFilters.map(attr => `${attr}`).join(' ') : ''
  // filter state
  const filter_state = state.activeFilters.length > 0 ? 'filterOn' : 'filterOff'

  const product_archive = productArchive ? `body-archive` : ''


  // __BODY CLASS SUM:
  const bodyClass = `${gatsby_Id} ${menu_state} ${autocomp} ${body_lang} ${subpage_id} ${page_type} ${filter_state} ${product_archive}`

  return (
    <Helmet
      bodyAttributes={{ class: bodyClass }}

      // title={metaTitle}

      meta={[
        {
          name: `robots`,
          content: `${gatsby_Id === '404' ? 'noindex' : 'index'}, follow`
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content:
            // "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
            // "width=device-width, initial-scale=1.0, maximum-scale=3.0",
            `width=device-width, initial-scale=1.0, maximum-scale=${pageType === 'product' ? '3.0' : '1.0'}${pageType !== 'product' ? ', user-scalable=no' : ''}`,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: `${data.site.siteMetadata.siteUrl}`,
        },
        {
          property: `og:url`,
          // content: `${data.site.siteMetadata.siteUrl}/${slug}`,

          // content: `${canonicalCombi ? data.site.siteMetadata.siteUrl + canonicalCombi : seo.canonical.substring(0, 1) === '/' ? data.site.siteMetadata.siteUrl + seo.canonical : seo.canonical}`,

          content: `${canonicalCombi ? data.site.siteMetadata.siteUrl + canonicalCombi : (typeof seo.canonical !== 'undefined' && seo.canonical !== "" && seo.canonical !== null) ? (seo.canonical.substring(0, 1) === '/' ? data.site.siteMetadata.siteUrl + seo.canonical : seo.canonical) : ''}`,

        },
        {
          property: `og:image`,
          content: data.wp.seo.openGraph.defaultImage?.mediaItemUrl,
        },
        {
          property: `og:locale`,
          content: lang,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },

        // {
        //   name: `twitter:creator`,
        //   content: wpUser?.twitter || ``,
        // },
        
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
          metaKeywords && metaKeywords.length > 0 ? {
            name: `keywords`,
            content: metaKeywords.join(`, `)
          } : []
        )
      }
    >

      <title>{metaTitle}</title>

      {( (typeof seo.canonical !== 'undefined' && seo.canonical !== "" && seo.canonical !== null) || canonicalCombi ) && <link rel="canonical" href={`${canonicalCombi ? data.site.siteMetadata.siteUrl + data.site.pathPrefix + canonicalCombi : seo.canonical.substring(0, 1) === '/' ? data.site.siteMetadata.siteUrl + data.site.pathPrefix + seo.canonical : seo.canonical}`} />}
      
      <script type="application/ld+json" key={10}>
        {/* {JSON.stringify(schemaMarkup)} */}
        {seo.schema?.raw ? seo.schema.raw : data.wp.seo.contentTypes.post.schema.raw}
      </script>

      {/* <script type="application/javascript" src={`${data.site.siteMetadata.siteUrl}/jsInitialPreloader.js`} defer={true} key={11}></script> */}
      
      <html lang={lang} />
    </Helmet>
  )
}

export default SEO