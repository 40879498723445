import React, { useState, useRef, useContext, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ScrollTop from 'src/components/layouts/scrollTop'

import MenuList from 'src/components/common/menuList'

import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'

import logo1 from '../../../static/ueicons/logo_1.png'
import logo2 from '../../../static/ueicons/logo_2.png'
import logo3 from '../../../static/ueicons/logo_3.png'
import logo4 from '../../../static/ueicons/logo_4.png'

import FbIco from 'src/images/socialIcons/facebook.js'
import InIco from 'src/images/socialIcons/instagram.js'
import LiIco from 'src/images/socialIcons/linkedin.js'
import PiIco from 'src/images/socialIcons/pinterest.js'
import TikTokIco from 'src/images/socialIcons/tiktok.js'
import StravaIco from 'src/images/socialIcons/strava.js'
import YtIco from 'src/images/socialIcons/youtube.js'

// import 'src/styles/global.scss'
// import 'src/styles/footer.scss'


const Footer = ({ pageContext, logo, windowWidth, phoneHrefClean, phoneHrefClean2, phoneHref, phoneHref2, mailHref, mailHref2, pageLoad }) => {
  const graphqlResult = useStaticQuery(graphql`
    query FooterQuery {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      footerInfo: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "footer-info-column"}}) {
        acfgeneralinfosfootercolumn_layout {
          line0GenralInfosFooterColumn
          line1GenralInfosFooterColumn
          line2GenralInfosFooterColumn
          line3GenralInfosFooterColumn
          line4GenralInfosFooterColumn
          line5GenralInfosFooterColumn
          line6GenralInfosFooterColumn
          line7GenralInfosFooterColumn
          line8GenralInfosFooterColumn
          line9GenralInfosFooterColumn
          line10GenralInfosFooterColumn
          line11GenralInfosFooterColumn
          line12GenralInfosFooterColumn
          line13GenralInfosFooterColumn
          buttonOpenFooterLabel
          buttonCloseFooterLabel
        }
      }
      generalFooterMenu: wpMenu (name:{eq: "general-footermenu"}) {
        name
        id
        menuItems {
          nodes {
            label
            parentId
            childItems {
              nodes {
                id
              }
            }
            path
            id
            cssClasses
            order
          }
        }
      }
      mostPopularFooterMenu: wpMenu (name:{eq: "Most popular footermenu"}) {
        name
        id
        menuItems {
          nodes {
            label
            parentId
            childItems {
              nodes {
                id
              }
            }
            path
            id
            cssClasses
            order
          }
        }
      }
    }
  `)
  const state = useContext(GlobalStateContext)

  const currentLang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo

  const footerInfo = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line0GenralInfosFooterColumn
  const footerInfo1 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line1GenralInfosFooterColumn
  const footerInfo2 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line2GenralInfosFooterColumn
  const footerInfo3 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line3GenralInfosFooterColumn
  const footerInfo4 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line4GenralInfosFooterColumn
  const footerInfo5 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line5GenralInfosFooterColumn
  const footerInfo6 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line6GenralInfosFooterColumn
  const footerInfo7 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line7GenralInfosFooterColumn
  const footerInfo8 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line8GenralInfosFooterColumn
  const footerInfo9 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line9GenralInfosFooterColumn
  const footerInfo10 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line10GenralInfosFooterColumn
  const footerInfo11 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line11GenralInfosFooterColumn
  const footerInfo12 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line12GenralInfosFooterColumn
  const footerInfo13 = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.line13GenralInfosFooterColumn
  const buttonOpenFooterLabel = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.buttonOpenFooterLabel
  const buttonCloseFooterLabel = graphqlResult.footerInfo.acfgeneralinfosfootercolumn_layout.buttonCloseFooterLabel

  // console.log(graphqlResult.mostPopularFooterMenu)
  const mostPopularMenu = graphqlResult.mostPopularFooterMenu


  const [footerOpen, setFooterOpen] = useState(false)

  /*    FOOTER MEASURE    */
  function footerInitial() {
    return 0
  }
  const fHeightRef = useRef(null)
  const[footerHeight, setFooterHeight] = useState(() => footerInitial())

  useLayoutEffect(() => {
    const fHeight = fHeightRef.current.getBoundingClientRect()
    setFooterHeight(parseInt(fHeight.height))
  }, [windowWidth])

  // console.log(pageLoad)

  // console.log(footerHeight ? 'fheight true' : 'fheight fols')

  return (
    <>
      <div 
        className={`${footerOpen ? 'footer--open-dependant-ScrollTop' : ''}`}
        style={footerOpen ? {display: 'none'} : {}}
      >
        <ScrollTop />
      </div>

      <a
        style={
          (footerOpen && windowWidth > 992) ? 
          {bottom: `175px`} 
          : 

          // {bottom: `30px`} 
          {
            // bottom: `20px`   //  value 'with LIVECHAT'
          }  



          // windowWidth > 660 ? 

          //   //  value 'with LIVECHAT'
          //   {
          //     bottom: `20px`,
          //     right: '90px'
          //   } 
          //   : 
          //   {bottom: `92px`}

          //   // {bottom: `30px`} 
          //   // : 
          //   // {bottom: `30px`}


        }
        className={`phone ${footerOpen ? 'footer--open-dependant' : ''} ${state.menu ? 'menu--open-dependant' : ''}`}
        href={`tel:${phoneHrefClean}`}
      >
        <span className={`iconNumber`}>{phoneHref}</span>
        <svg 
          // aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" 
          className="svg-inline--fa fa-phone-alt fa-w-16 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path 
            className={`notHover`}
            fill="currentColor" 
            d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
          ></path>
        </svg>
      </a>

      <footer
        ref={fHeightRef}
        id="footer"
        className={`footer ${footerOpen ? 'footer--open' : ''} ${pageLoad ? 'pageLoad' : ''} ${footerHeight ? 'docReady' : ''}`}
        style={
          {
            // bottom: `${pageLoad ? '-1500' : (footerOpen ? 0 : (footerHeight + 12) * -1 )}px`,
            bottom: `${!footerHeight ? '-1500' : (footerOpen ? 0 : (footerHeight + 12) * -1 )}px`,
            display: 'hidden'
          }
        }
        // onClick={() => {!footerOpen && setFooterOpen(!footerOpen)}}
        // onKeyDown={() => {!footerOpen && setFooterOpen(!footerOpen)}}
        // role="button"
        // tabIndex="0"
        // aria-label="navigation"
      >
        {footerOpen && 
          <div 
            className="footClose"
            style={{
              minHeight:'100vh',
              minWidth:'100vw',
              position:'fixed',
              top:'0',
              left:'0',
              zIndex: -1,
              cursor: 'pointer'
            }} 
            onClick={() => {setFooterOpen(!footerOpen)}}
            onKeyDown={() => {setFooterOpen(!footerOpen)}}
            role="button"
            tabIndex="0"
          >
          </div>
        }
        <div className="content">
          <span className="footer_button"
            onClick={() => {setFooterOpen(!footerOpen)}}
            onKeyDown={() => {setFooterOpen(!footerOpen)}}
            role="button"
            tabIndex="0"
          >
           

            {footerOpen ? 
            <>
              <svg 
                  // className="footer_button_bottom-arrow"
                  style={{width:'14px',height:'25px'}} 
                  // aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" 
                  className="svg-inline--fa fa-chevron-up fa-w-14 footer_button_bottom-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                >
                  <path 
                    fill="#f3cf45" 
                    d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
                  ></path>
                </svg>
              <span style={{marginLeft: '25px', fontSize: '14px', color: '#f3cf45', fontWeight: 'bold'}}>{buttonCloseFooterLabel}</span>
              {/* <svg 
                className="footer_button_bottom-arrow"
                style={{width:'22px',height:'25px'}} 
                // aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down"
                className="svg-inline--fa fa-chevron-down fa-w-14 footer_button_bottom-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
              >
                <path 
                  // fill="currentColor" 
                  fill="#f3cf45" 
                  d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                ></path>
              </svg> */}
            </>
              :
            <>
              {/* <svg 
                className="footer_button_top-arrow"
                style={{width:'22px',height:'25px'}} 
                // aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" 
                className="svg-inline--fa fa-chevron-up fa-w-14 footer_button_top-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
              >
                <path 
                  fill="#f3cf45" 
                  d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
                ></path>
              </svg> */}
              <div className='wrapper'>
                <svg 
                  // className="footer_button_top-arrow"
                  style={{width:'14px',height:'26px'}} 
                  // aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down"
                  className="svg-inline--fa fa-chevron-down fa-w-14 footer_button_top-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                >
                  <path 
                    // fill="currentColor" 
                    fill="#f3cf45" 
                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                  ></path>
                </svg>
                <span>{buttonOpenFooterLabel}</span>
              </div>
            </>
            }

          </span>
        </div>
        <div className="content footer-columns">
          <div className={`footer-info column socialMenu`}>
            {footerOpen && <img src={logo} alt="logo" width="40px"></img>}
            <span style={{display:'block'}} >{footerInfo && footerInfo}</span>
            <span style={{display:'block'}} >{footerInfo1 && footerInfo1}</span>
            <span style={{display:'block'}} >{footerInfo2 && footerInfo2}</span>
            <span style={{display:'block'}} >{footerInfo3 && footerInfo3}</span>
            <span style={{display:'block',fontWeight:'bold',marginBottom:'4px',marginTop:'4px'}} >{footerInfo4 && footerInfo4}</span>

            <span style={{display:'block',marginBottom:'4px'}} >
              <span style={{display:'block'}} className='accnum' >{footerInfo7 && footerInfo7} </span>
              <span style={{display:'block'}} className='accnum' >{footerInfo5 && footerInfo5}</span>
            </span>

            <span style={{display:'block',marginBottom:'4px'}} >
              <span className='accnum'>{footerInfo8 && footerInfo8} </span>
              <span style={{whiteSpace:'nowrap', display:'block'}} className='accnum' >{footerInfo9 && footerInfo9}</span>
            </span>

            <span style={{display:'block',marginBottom:'4px'}} >
              <span className='accnum'>{footerInfo10 && footerInfo10} </span>
              <span style={{whiteSpace:'nowrap', display:'block'}} className='accnum'>{footerInfo11 && footerInfo11}</span>
            </span>

            <span style={{display:'block',marginBottom:'4px'}} >
              <span className='accnum'>{footerInfo12 && footerInfo12} </span>
              <span style={{whiteSpace:'nowrap', display:'block'}} className='accnum'>{footerInfo13 && footerInfo13}</span>
            </span>

            <a className={`menuLink cta2`} href={`mailto:${footerInfo6}`}>{footerInfo6}</a>
            {/* <a className={`menuLink cta2`} href={`tel:${currentLang !== 'de-DE' ? phoneHrefClean : phoneHrefClean2}`}>{currentLang !== 'de-DE' ? phoneHref : phoneHref2}</a> */}
            <a className={`menuLink cta2`} href={`tel:${phoneHrefClean}`}>{phoneHref}</a>

            <div className={`socIcoWrap`}>
              <a 
                // href="https://www.facebook.com/themodernforms" 
                href={currentLang === 'pl-PL' ? 'https://www.facebook.com/modernformspolska' : 'https://www.facebook.com/modernformscom'} 
                target="_blank" 
                rel="noreferrer" 
                className={`socIco`}
              >
                <FbIco />
              </a>
              <a href="https://www.instagram.com/themodernforms/" target="_blank" rel="noreferrer" className={`socIco`}>
                <InIco />
              </a>
              <a href="https://www.linkedin.com/company/modern-forms" target="_blank" rel="noreferrer" className={`socIco`}>
                <LiIco />
              </a>
              <a href="https://pl.pinterest.com/themodernforms/_created/" target="_blank" rel="noreferrer" className={`socIco`}>
                <PiIco />
              </a>
              <a href="https://www.tiktok.com/@themodernforms" target="_blank" rel="noreferrer" className={`socIco`}>
                <TikTokIco />
              </a>
              <a href="https://www.strava.com/clubs/964300" target="_blank" rel="noreferrer" className={`socIco`}>
                <StravaIco />
              </a>
              <a href="https://www.youtube.com/channel/UCPmAyp3GvSFdkBWlIeHnZOQ/" target="_blank" rel="noreferrer" className={`socIco`}>
                <YtIco />
              </a>
            </div>

          </div>

          <div className="generalFooterMenu column">
            <MenuList
              page={pageContext.pageSlug}
              site={pageContext.siteSlug}
              pageContext={pageContext}
              list={graphqlResult.generalFooterMenu.menuItems.nodes}
            />
          </div>

          <div className="switchMenu column">
            <MenuList 
              // page={pageContext.pageSlug}
              // site={pageContext.siteSlug}
              // pageContext={pageContext}
              pagePath={pageContext.pagePath} 
              list={mostPopularMenu.menuItems.nodes} 
              pathMod={'wp'} 
              defaultState={''}
              mobNavLabel={''}
            />
          </div>

          <div className="socialMenu column"></div>

        </div>
        {footerOpen && <div className={`uelogosEXT`} >
          <div className={`uelogos`} >
            <div><img src={logo1} alt="ue logo"></img></div>
            <div><img src={logo2} alt="ue logo"></img></div>
            <div><img src={logo3} alt="ue logo"></img></div>
            <div><img src={logo4} alt="ue logo"></img></div>
          </div>
        </div>}
      </footer>

    </>
  )
}
export default Footer