import React, { useState, useEffect, useCallback } from 'react'


const ScrollTop = () => {

  const [scrolluser, setScrolluser] = useState(0)
  const [showButt, setShowButt] = useState(false)

  // function onScroll() {
  //   setScrolluser(window.pageYOffset)
  //   if(scrolluser > 288 && showButt === false) {
  //     setShowButt(true)
  //   } 
  //   else if(scrolluser <= 288 && showButt === true) {
  //     setShowButt(false)
  //   }
  // }

  const onScroll = useCallback(() => {
    setScrolluser(window.pageYOffset)
    if(scrolluser > 288 && showButt === false) {
      setShowButt(true)
    } 
    else if(scrolluser <= 288 && showButt === true) {
      setShowButt(false)
    }
  }, [scrolluser,showButt])

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrolluser,onScroll]);
  
  const scrollToTop = () => {
    typeof window !== 'undefined' && window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

 
  return (
    <>
      {showButt && <div
        className={`scrollTopWrap`}
        style={{
          // position: 'fixed',
          // zIndex: '1100',
          // bottom: '120px',
          // right: '20px',
          // bottom: '30px',
          // right: '120px',
          // width: '44px',
          // height: '44px',
          // backgroundColor: '#f3cf45',
          // backgroundColor: '#000',
          // borderRadius: '50% 24px 0 24px',
          // transform: 'rotate(-135deg)'
        }}
      >
        <button
          className={`scrollTopButton`}
          style={{
            width: '100%',
            height: '100%'
          }}
          onClick={() => scrollToTop()}
        >

          <svg 
            className="footer_button_bottom-arrow svg-inline--fa fa-chevron-up fa-w-14 footer_button_bottom-arrow"
            // style={{width:'22px',height:'25px'}} 
            // aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" 
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
          >
            <path 
              // fill="#f3cf45" 
              // fill="#fff" 
              d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
            ></path>
          </svg>

          <svg 
            className="footer_button_bottom-arrow svg-inline--fa fa-chevron-up fa-w-14 footer_button_bottom-arrow"
            // style={{width:'22px',height:'25px'}} 
            // aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" 
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
          >
            <path 
              // fill="#f3cf45" 
              // fill="#fff"
              d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
            ></path>
          </svg>

        </button>
      </div>}
    </>
  )
}
export default ScrollTop