import React from 'react'
function LeftPaginate() {
  return (
        <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1003_1683)">
        <path d="M9.4526 19.5454C10.1578 19.2518 10.1036 18.5759 9.64699 18.1145C7.05206 15.4811 4.48425 12.8198 1.90287 10.1724C1.85314 10.1211 1.78985 10.0792 1.69944 9.94867C1.76273 9.91138 1.83506 9.88808 1.88479 9.83681C4.49329 7.16613 7.09727 4.48613 9.70124 1.81545C9.93633 1.57309 10.0674 1.30742 9.96345 0.962514C9.80974 0.473123 9.2356 0.29601 8.84229 0.612949C8.79256 0.654897 8.74284 0.701506 8.69763 0.752775C5.89925 3.62853 3.09636 6.50429 0.302509 9.38936C0.171406 9.52453 0.071949 9.71096 0.0222197 9.89274C-0.0410709 10.1071 0.0403032 10.3215 0.189489 10.494C0.239218 10.5499 0.288947 10.6012 0.338675 10.6525C3.09184 13.4816 5.84501 16.3154 8.59817 19.1399C8.75188 19.2984 8.94627 19.4102 9.12258 19.5454C9.2356 19.5454 9.3441 19.5454 9.4526 19.5454Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_1003_1683">
        <rect width="10" height="19.0909" fill="white" transform="matrix(-1 0 0 1 10 0.45459)"/>
        </clipPath>
        </defs>
        </svg>
    )
}
export default LeftPaginate