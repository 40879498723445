import React from 'react'
function TikTokSvg() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
      <g id="surface1">
      <path d="M 19.320312 5.5625 C 19.167969 5.484375 19.023438 5.398438 18.878906 5.304688 C 18.460938 5.027344 18.078125 4.703125 17.742188 4.335938 C 16.890625 3.367188 16.574219 2.382812 16.457031 1.691406 L 16.464844 1.691406 C 16.367188 1.121094 16.40625 0.75 16.414062 0.75 L 12.546875 0.75 L 12.546875 15.691406 C 12.546875 15.894531 12.546875 16.089844 12.539062 16.289062 C 12.539062 16.3125 12.539062 16.335938 12.535156 16.359375 C 12.535156 16.371094 12.535156 16.382812 12.535156 16.394531 C 12.535156 16.398438 12.535156 16.398438 12.535156 16.402344 C 12.449219 17.492188 11.832031 18.46875 10.882812 19.007812 C 10.394531 19.285156 9.84375 19.429688 9.285156 19.429688 C 7.484375 19.429688 6.023438 17.960938 6.023438 16.148438 C 6.023438 14.335938 7.484375 12.867188 9.285156 12.867188 C 9.625 12.867188 9.964844 12.917969 10.289062 13.023438 L 10.292969 9.089844 C 8.304688 8.832031 6.296875 9.421875 4.761719 10.707031 C 4.09375 11.289062 3.535156 11.976562 3.105469 12.75 C 2.941406 13.03125 2.328125 14.160156 2.253906 15.996094 C 2.207031 17.035156 2.519531 18.113281 2.667969 18.558594 L 2.667969 18.570312 C 2.761719 18.832031 3.125 19.726562 3.714844 20.484375 C 4.195312 21.089844 4.757812 21.621094 5.390625 22.0625 L 5.390625 22.054688 L 5.398438 22.0625 C 7.269531 23.332031 9.34375 23.25 9.34375 23.25 C 9.703125 23.234375 10.90625 23.25 12.273438 22.601562 C 13.789062 21.886719 14.648438 20.816406 14.648438 20.816406 C 15.203125 20.175781 15.640625 19.449219 15.945312 18.664062 C 16.296875 17.742188 16.414062 16.640625 16.414062 16.199219 L 16.414062 8.273438 C 16.460938 8.300781 17.082031 8.714844 17.082031 8.714844 C 17.082031 8.714844 17.984375 9.289062 19.386719 9.667969 C 20.394531 9.933594 21.75 9.988281 21.75 9.988281 L 21.75 6.152344 C 21.273438 6.203125 20.308594 6.054688 19.320312 5.5625 Z M 19.320312 5.5625 "/>
      </g>
      </svg>
    </>
  )
}
export default TikTokSvg